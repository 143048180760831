<template>
  <div>
    <nav class="nav">
      <div class="inner">
        <div class="btn_wrap">
          <button :class="{ on: tab === 1 }" @click="fnTap(1)">
            <span>아이디 찾기</span>
          </button>
          <button :class="{ on: tab === 2 }" @click="fnTap(2)">
            <span>비밀번호 찾기</span>
          </button>
        </div>
      </div>
    </nav>
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="page_con">
            <div class="sign_page">
              <div class="form_wrap">
                <div class="form">
                  <ul class="form_ul">
                    <li class="form_li" v-show="tab === 2">
                      <div class="form_ttl">아이디</div>
                      <div class="form_in">
                        <div class="inline">
                          <label class="input">
                            <input
                              type="text"
                              placeholder="아이디"
                              v-model="userId"
                            />
                          </label>
                        </div>
                        <div class="hint" :class="{ on: valid.userId.$error }">
                          <p>{{ msg.userId }}</p>
                        </div>
                      </div>
                    </li>
                    <li class="form_li">
                      <div class="form_ttl">핸드폰 번호</div>
                      <div class="form_in">
                        <div class="inline">
                          <label class="input">
                            <input
                              type="number"
                              placeholder="번호를 입력해 주세요."
                              v-model="userPhone"
                              :disabled="auth.isSendAuth"
                              oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                              inputmode="decimal"
                              pattern="[0-9]*"
                            />
                          </label>
                          <button
                            class="btn bo"
                            @click="fnSendAuth"
                            :disabled="isDone"
                          >
                            {{ auth.label }}
                          </button>
                        </div>
                        <div class="hint on">{{ msg.userPhone }}</div>
                        <div class="inline" v-show="auth.isSendAuth">
                          <label class="input timer">
                            <input
                              type="number"
                              placeholder="코드"
                              v-model="auth.authCode"
                              :disabled="auth.isConfirm"
                              :readonly="isDone"
                              oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                              inputmode="decimal"
                              pattern="[0-9]*"
                            />
                            <span
                              class="time"
                              v-show="auth.isRunning"
                              style="width: 20%"
                              >{{ auth.count }}</span
                            >
                          </label>
                          <button
                            class="btn bo"
                            @click="fnConfirm"
                            :disabled="isAuthDone"
                          >
                            확인
                          </button>
                        </div>
                        <div class="hint on">{{ msg.authCode }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="page_btn">
            <div class="btn_wrap">
              <button
                class="btn"
                :class="{ on: isDone }"
                @click="fnFind"
                :disabled="!isDone"
              >
                {{ btnLabel }}
              </button>
              <!--입력되면 on-->
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { findState } from './index.js'

export default {
  name: 'find-index',
  description: '아이디/비밀번호 찾기 페이지',
  setup(props) {
    const state = findState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
