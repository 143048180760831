import { reactive, toRefs, getCurrentInstance, onUnmounted } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// import { useRoute } from 'vue-router'

export const findState = () => {
  const { proxy } = getCurrentInstance()
  const { dispatch } = useStore()
  const router = useRouter()

  const state = reactive({
    tab: 1,
    userId: '',
    userPhone: '',
    btnLabel: '아이디 찾기',
    auth: {
      label: '인증',
      authCode: '',
      authConfirmCode: '',
      isSendAuth: false,
      isConfirm: false,
      isRunning: false,
      count: '05:00',
      timeCount: 300,
      timer: ''
    },
    isDone: false,
    isAuthDone: false,
    msg: {
      userId: '',
      userPhone: '',
      authCode: ''
    },
    findUserId: ''
  })

  const fnTap = tab => {
    state.tab = tab
    fnStop()
    fnInit()
    if (tab === 1) {
      state.btnLabel = '아이디 찾기'
    } else {
      state.btnLabel = '비밀번호 찾기'
    }
  }

  const fnInit = () => {
    state.userId = ''
    state.userPhone = ''
    state.isDone = false
    state.isAuthDone = false
    state.auth.label = '인증'
    state.auth.count = '05:00'
    state.auth.timeCount = 300
    state.auth.authCode = ''
    state.auth.isRunning = false
    state.auth.isConfirm = false
    state.auth.isSendAuth = false
  }

  // 인증번호 최초 클릭시
  const fnSendAuth = async () => {
    if (proxy.$Util.isEmpty(state.userPhone)) {
      state.msg.userPhone = '휴대폰 번호를 입력해주세요.'
      return
    }
    if (!proxy.$Util.isPhoneValid(state.userPhone)) {
      state.msg.userPhone = '휴대폰 형식이 올바르지 않습니다.'
      return
    } else {
      state.msg.userPhone = ''
    }
    if (state.auth.label === '재시도') {
      fnStop()
      state.msg.authCode = ''
      state.auth.label = '인증'
      state.auth.authCode = ''
      state.auth.isSendAuth = false
      state.auth.isRunning = false
      state.auth.isConfirm = false
      return false
    }
    const res = await proxy.$SignSvc.send({ phone: state.userPhone })
    if (res.code === 1) {
      alert('인증번호가 발송되었습니다.')
      state.auth.authConfirmCode = res.entity
      state.auth.isSendAuth = true
      state.auth.label = '재시도'
      state.auth.isRunning = true
      state.auth.isConfirm = false
      state.auth.authCode = ''
      fnTimer()
    }
  }

  // 인증번호가 일치여부 확인
  const fnConfirm = async () => {
    if (proxy.$Util.isEmpty(state.auth.authCode)) {
      state.msg.authCode = '인증번호를 입력해주세요.'
      return
    } else {
      state.msg.authCode = ''
    }
    if (state.auth.authCode === state.auth.authConfirmCode) {
      alert('인증되었습니다.')
      fnStop()
      state.auth.isRunning = false
      state.isAuthDone = true
      state.isDone = true
    } else {
      alert('인증번호가 일치하지 않습니다.')
    }
  }

  const fnTimer = () => {
    fnStop()
    state.auth.timer = setInterval(() => {
      state.auth.timeCount--
      if (state.auth.timeCount <= 0) {
        fnStop()
        alert('인증번호가 만료되었습니다. \n다시 인증해주세요')
        state.auth.isConfirm = true
        state.isAuthDone = true
        state.auth.isRunning = false
      }
      let time = state.auth.timeCount / 60
      let min = parseInt(time)
      let sec = Math.round((time - min) * 60)
      state.auth.count = pad(min, 2) + ':' + pad(sec, 2)
    }, 1000)
  }

  const fnStop = () => {
    clearInterval(state.auth.timer)
    state.auth.timeCount = 300
    state.auth.count = '05:00'
  }

  const pad = (num, width) => {
    num = num + ''
    return num.length >= width
      ? num
      : new Array(width - num.length + 1).join('0') + num
  }

  const rules = {
    userId: {
      test: function(val) {
        if (state.tab === 2) {
          if (proxy.$Util.isEmpty(val)) {
            state.msg.userId = '아이디를 입력해 주세요.'
            return false
          }
          if (val.length < 6) {
            state.msg.userId = '아이디는 6자 이상 입력해야 합니다.'
            return false
          }
        }
        return true
      }
    }
  }

  const valid = useVuelidate(rules, state)

  // 아이디,비밀번호 찾기
  const fnFind = async () => {
    valid.value.$touch()
    if (valid.value.$invalid) return
    if (state.tab === 1) {
      state.type = state.tab
      const res = await proxy.$SignSvc.findUser(state)
      if (res.code === 1) {
        await fnModalAlert(res.entity.userId)
      } else {
        await fnModalBaseAlert()
      }
    } else {
      state.type = state.tab
      const res = await proxy.$SignSvc.findUser(state)
      if (res.code === 1) {
        await router.push({
          path: '/find/reset',
          query: {
            userNo: res.entity.userNo
          }
        })
      } else {
        await fnModalBaseAlert()
      }
    }
  }

  const fnModalBaseAlert = async () => {
    const payload = {}
    payload.component = proxy.$modalAlertNames.BASE
    payload.layout = {
      title: '알림',
      contents:
        state.tab === 1
          ? '해당 휴대폰은 회원으로 등록되어 있지 않습니다. \n다시 시도해 주세요.'
          : 'ID와 휴대폰 번호가 일치하지 않습니다.\n 다시 확인 후 시도해 주세요.'
    }
    payload.callBack = proxy => {
      proxy.$emit('close')
      fnInit()
    }
    await dispatch('layout/pushModalAlertComponent', payload)
  }

  const fnModalAlert = async item => {
    const payload = {}
    payload.component = proxy.$modalAlertNames.CONFIRM
    payload.layout = {
      title: '아이디 찾기',
      contents: proxy.$Util.setMasking(item, 3)
    }
    payload.data = {}
    payload.doneText = '로그인'
    payload.cancelText = '비밀번호 찾기'
    payload.callBackDone = proxy => {
      router.push({ path: '/sign/in' })
      proxy.$emit('close')
    }
    payload.callBackCancel = proxy => {
      proxy.$emit('close')
      state.tab = 2
      state.btnLabel = '비밀번호 찾기'
      fnInit()
    }
    await dispatch('layout/pushModalAlertComponent', payload)
  }

  onUnmounted(() => {
    fnStop()
  })

  return {
    fnTap,
    ...toRefs(state),
    fnSendAuth,
    fnConfirm,
    fnFind,
    valid
  }
}
